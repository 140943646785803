import React, { FC, memo } from 'react'

import Layout from '@components/layout'
import Typography from '@ui/typography'
import { headers } from '@utils/constants'

const NotFound: FC = () => (
  <Layout center>
    <Typography type="h2_bold">Страница не найдена</Typography>
  </Layout>
)

export async function getServerData() {
  return { headers }
}

export default memo(NotFound)
